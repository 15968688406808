import * as React from "react";

import { Link } from "gatsby";

import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

import landingPageStyle from "assets/jss/material-kit-pro-react/views/landingPageStyle";

import Footer from "components/Footer/Footer";

const useStyles = makeStyles(landingPageStyle);

const MyFooter = ({ ...rest }) => {
  const classes = useStyles();
  return (
    <Footer
      content={
        <div>
          <div className={classes.left}>
            <List className={classes.list}>
              <ListItem className={classes.inlineBlock}>
                <Link to="/about" className={classes.block}>
                  About
                </Link>
              </ListItem>
              <ListItem className={classes.inlineBlock}>
                <Link to="/privacy" className={classes.block}>
                  Privacy Policy
                </Link>
              </ListItem>
            </List>
          </div>
          <div className={classes.right}>
            &copy; Thought Development Corporation {1900 + new Date().getYear()}
            . All Rights Reserved.
          </div>
        </div>
      }
    />
  );
};

export default MyFooter;
