import * as React from "react";

import Header from "./Header";
import HeaderLinks from "./HeaderLinks";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import Logo from "assets/svg/tdc-logo.inline.svg";

const MyHeader = ({ ...rest }) => {
  return (
    <Header
      color="transparent"
      brand={
        <GridContainer alignItems="flex-end">
          <GridItem xs={6}>
            <Logo height={80} width={80} fill="#fff" />
          </GridItem>
          <GridItem xs={6}>
            <GridContainer direction="column">
              <GridItem style={{ padding: "0 0 0 15px", lineHeight: 1 }}>
                <small
                  style={{
                    color: "#fff",
                    fontStyle: "italic",
                  }}
                >
                  TRACE YOUR
                </small>
              </GridItem>
              <GridItem style={{ padding: "0 21px 0 0", lineHeight: 1 }}>
                <small
                  style={{
                    color: "#fff",
                    fontStyle: "italic",
                  }}
                >
                  ELEMENTS
                </small>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      }
      links={<HeaderLinks dropdownHoverColor="info" />}
      fixed
      changeColorOnScroll={{
        height: 100,
        color: "dark",
      }}
      {...rest}
    />
  );
};

export default MyHeader;
